import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"color": "--darkD2",
	"quarkly-title": "Contacts-8"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "60%",
			"lg-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"lg-margin": "0px 0 30px 0px",
			"align-items": "flex-start",
			"sm-margin": "0px 0px 0 0px",
			"lg-padding": "0px 80px 0px 0px",
			"sm-padding": "0px 0 0px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 600 38px/1.2 --fontFamily-sansHelvetica",
			"color": "--dark",
			"children": "Залишайтеся на зв'язку"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 50px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"color": "--darkD2",
			"lg-margin": "0px 0px 20px 0px",
			"md-width": "100%",
			"children": "Не соромтеся звертатися до нашої команди. Ми прагнемо надати вам всю інформацію, необхідну для безперешкодної та елегантної морської подорожі."
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"grid-gap": "16px 24px",
			"margin": "0px 0 0 10%",
			"lg-grid-gap": "36px 34px",
			"flex-direction": "column",
			"width": "50%",
			"lg-margin": "0px 0 0 0",
			"lg-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center",
			"margin": "0px 0px 30px 0px",
			"lg-margin": "0px 0px 10px 0px",
			"sm-margin": "0px 0px 0 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"md-justify-content": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"font": "normal 500 25px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"sm-font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"children": "вул. Приморська 6"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--darkD2",
			"md-margin": "0px 0px 0px 0px",
			"children": <>
				{" "}м. Одеса, 65000
			</>
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center",
			"margin": "0px 0px 30px 0px",
			"lg-margin": "0px 0px 10px 0px",
			"sm-margin": "0px 0px 0 0px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"md-justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:(050) 312 32 62",
			"color": "--dark",
			"text-decoration-line": "initial",
			"font": "normal 500 25px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"sm-font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"children": "(050) 312 32 62"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start",
			"md-justify-content": "center"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:info@ua.petadazen.com",
			"color": "--dark",
			"text-decoration-line": "initial",
			"font": "normal 500 25px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"sm-font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"children": "info@ua.petadazen.com"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Link {...override("link")} />
				</Box>
			</Box>
			<Box {...override("box6")}>
				<Box {...override("box7")}>
					<Link {...override("link1")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;